import { useEffect, useState } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useDispatch } from 'react-redux'
import { theme } from './Layout/theme'
import Layout from './Layout'
import Navbar from './Layout/Navbar'
import { RoutesContainer } from './Routes'
import { BrowserRouter } from 'react-router-dom'
import { setUserData } from './redux/user/userSlice'
import Home from './pages/Home'
import Login from './pages/Login'
import { useAppSelector } from './redux/store'
import { arrayMinSize } from 'class-validator'
import ReactGA from "react-ga4";
function App() {
    ReactGA.initialize("G-M805H4BJDR");

    const dispatch = useDispatch()
    const { instance, accounts, inProgress } = useMsal()
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const account = accounts[0] && accounts[0]
    const isAuthenticated = useIsAuthenticated()
    const [auth, setauth] = useState(false)
    const admins = useAppSelector((state) => state.mainState.admin)
    const userEmail = useAppSelector((state) => state.userState.email)

    useEffect(() => {
        setauth(isAuthenticated)
        if (account) {
            dispatch(
                setUserData({
                    isAuth: isAuthenticated,
                    userId: account.homeAccountId,
                    userName: account.name,
                    email: account.username,
                })
            )
        }
    }, [isAuthenticated, accounts])

    useEffect(() => {
        if (admins && arrayMinSize.length > 0 && userEmail) {
            const isAdmin = admins.find(
                (admin: any) => admin.email === userEmail
            )

            dispatch(
                setUserData({
                    isAdmin: isAdmin ? true : false,
                })
            )
        }
    }, [admins, userEmail])

    return (
        // <BrowserRouter>
        <ChakraProvider theme={theme}>
            <Layout>
                <Navbar isAuth={auth} />
                {/* <RoutesContainer isAuthenticated={auth} /> */}
                {auth ? <Home /> : <Login />}
            </Layout>
        </ChakraProvider>
        // </BrowserRouter>
    )
}

export default App
