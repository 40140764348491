import { Text, Flex, Button, Link } from '@chakra-ui/react'
import { FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa'
import {
    deleteIdAction,
    opencreateOrEditIdModal,
    setcreateOrEditId,
} from '../redux/createOrEditId/createOrEditId'
import IId from '../redux/selectedNode/id.interface'
import { ParamType } from '../redux/selectedNode/slice'
import { useAppDispatch, useAppSelector } from '../redux/store'
import ReactGA from "react-ga4";
export interface ITableRowProps {
    item: IId
}

export interface ITableRowItem {
    key: string
    value: string
}
function TableRow({ item }: ITableRowProps) {
    const isAdmin = useAppSelector((state) => state.userState.isAdmin)

    const dispatch = useAppDispatch()
    const setIsOpen = (openModal: boolean) =>
        dispatch(opencreateOrEditIdModal({ openModal, isUpdate: true }))

    const onOpen = () => setIsOpen(true)

    return (
        <Flex color="black" fontWeight={600} direction="column" py={1} mt={0}>
            <Flex justifyContent="space-around" px={2} direction="column">
                <Flex justifyContent="space-between">
                    <Flex justifyContent="center" direction="column" pr={1}>
                        <Text fontSize={15}>{item.title}</Text>
                        <Link
                            color="rgb(0 200 182)"
                            href={`dobricks://${encodeURIComponent(item.ID_verwijzing)}`}
                            isExternal
                            fontSize={15}
                            fontWeight="400"
                            onClick={() => {

                                 ReactGA.send({
                                        hitType: "pageview",
                                        page:  item.ID_verwijzing ,
                                        title: item.IdInput
                                 });
                                
                                // ReactGA.event({
                                //     category: "link",
                                //     action: item.ID_verwijzing,
                                //   });
                            }}
                        >
                            {item.IdInput}
                        </Link>
                    </Flex>
                    <Flex justifyContent="flex-end" py={1}>
                        {isAdmin && (
                            <Button
                                leftIcon={<FaPencilAlt />}
                                colorScheme="teal"
                                variant="link"
                                onClick={() => {
                                    dispatch(
                                        setcreateOrEditId({
                                            id: { input: item.IdInput },
                                            kennisgebied: {
                                                input: item.Kennisgebied,
                                            },
                                            title: {
                                                input:
                                                    item.title || 'Applicatie',
                                            },
                                            categories: {
                                                input: item.Categorie,
                                            },
                                            onderdeels: {
                                                input: item.Onderdeel,
                                            },
                                            verwijzing: {
                                                input: item.ID_verwijzing,
                                            },
                                            dbId: item.id,
                                            id_status: {
                                                input: item.ID_status,
                                            },
                                            trefwoorden: {
                                                input: item.Trefwoorden,
                                            },
                                            contactpersoon: {
                                                input: item.ID_contactpersoon,
                                            },
                                        })
                                    )
                                    onOpen()
                                }}
                            ></Button>
                        )}
                        {isAdmin && (
                            <Button
                                leftIcon={<FaRegTrashAlt />}
                                colorScheme="red"
                                variant="link"
                                onClick={() => {
                                    dispatch(
                                        deleteIdAction({
                                            id: item.id,
                                            refreshData: {
                                                category: {
                                                    name: item?.Categorie,
                                                },
                                                kennisgebied: {
                                                    name: item.Kennisgebied,
                                                },
                                                onderdeel: {
                                                    name: item.Onderdeel,
                                                },
                                            },
                                        })
                                    )
                                }}
                            ></Button>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            </Flex>
        
    )
}

export default TableRow
