import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import Color from '../Layout/Colors'

function FrontPagePlaceHolder() {
    return (
        <Flex flex={1} bg="white" py={4} boxShadow="xl">
            <VStack align="stretch" flex={1}>
                <Box bg={Color.mintGreen} height={8}></Box>
                <Flex
                    justifyContent="space-between"
                    alignItems="start"
                    direction="column"
                    py={8}
                    px={16}
                >
                    <Text
                        fontSize={18}
                        fontWeight="600"
                        color={Color.mintGreen}
                    >
                        Gevalideerde software op één plek
                    </Text>
                    <Text>
                        BRiCks begeleidt gebruikers naar de juiste gevalideerde
                        (reken-)software. Via BRiCks worden Excelsheets en
                        applicaties van softwareleveranciers ontsloten én
                        voorzien van een label: voorkeur, acceptabel of nog te
                        valideren. Gebruik zoveel mogelijk de voorkeursvariant
                        en stap alleen over naar "acceptabel" als dat
                        noodzakelijk is.{' '}
                    </Text>
                </Flex>
                <Flex
                    justifyContent="space-between"
                    alignItems="start"
                    direction="column"
                    py={8}
                    px={16}
                >
                    <Text
                        fontSize={18}
                        fontWeight="600"
                        color={Color.mintGreen}
                    >
                        Voor en door Oosterhoff
                    </Text>
                    <Text>
                        Collega's, kennisgroepen en werkmaatschappijen van
                        Oosterhoff kunnen met BRiCks aan de slag. Ieders
                        bijdrage en feedback is welkom om de kwaliteit van ons
                        werk op peil te houden. Wil je een Excelsheet of
                        applicatie aan BRiCks toevoegen of heb je vragen, klik
                        dan rechtsboven op Help.
                    </Text>
                </Flex>
            </VStack>
        </Flex>
    )
}

export default FrontPagePlaceHolder
